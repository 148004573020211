import React, { useContext, useState, useEffect} from 'react'
import { auth, firestore, firestorage } from '../pages/firebase/firebase'
import { AuthContext } from "../App"
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { addDoc, collection, doc, setDoc, getDocs, getDoc ,  updateDoc,
} from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {

    async function uploadstorage(img, id, name) {
        console.log(img)
        const storageRef = ref(firestorage, "insurance/" + id + "/" + name);
        uploadBytes(storageRef, img).then((snapshot) => {
        });
    }

    const [currentusr, setcurrentusr] = useState()

    async function login(email,password) {
        
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password)
            // Signed in 
            setcurrentusr (userCredential.user)
        } catch (error) {
            const errorCode = error.code
            const errorMessage = error.message
        }
    }

    async function signout() {
        
        try {
            const userCredential = await signOut(auth)
            // Signed out 
            setcurrentusr (null)
        } catch (error) {
            const errorCode = error.code
            const errorMessage = error.message
        }

    }

    async function readata(collection_name) {

        let res = []
        let i = 0

        const querySnapshot = await getDocs(collection(firestore, collection_name));
        querySnapshot.forEach((doc) => {

            res[i] = {idnum: doc.id ,data: doc.data()}
            i++
            
        });
        
        return res
        
    }

    async function writedata_id(collec, id, data) {
        
        try {
            await setDoc(doc(firestore, collec, id), {data}, { capital: true }, { merge: true });
        } catch (error) {
            const errorMessage = error.message
            console.log("error setting doc");
        }

    }

    async function writedata_search(collec, id, search) {
        
        try {
            await setDoc(doc(firestore, collec, id), {search}, { merge: true });
        } catch (error) {
            const errorMessage = error.message
            console.log("error setting doc");
        }

    }

    async function writedata(collec, data) {
        var doc
        try {
           doc = await addDoc(collection(firestore, collec), {data}, { capital: true }, { merge: true });
        } catch (error) {
            const errorMessage = error.message
            console.log("error setting doc");
        }
        return doc.id
    }

    async function openimg(link) {
        getDownloadURL(ref(firestorage, link)).then((url) => {window.location.href = url;})
    }

    async function updatedata(collec, updatedFields) {
        try {
          const docRef = doc(firestore, collec);
          const nestedUpdates = {};
      
          // Prefix keys with `data.` to match Firestore structure
          Object.keys(updatedFields).forEach((key) => {
            nestedUpdates[`data.${key}`] = updatedFields[key];
          });
      
          await updateDoc(docRef, nestedUpdates);
          console.log("Document updated successfully:", nestedUpdates);
        } catch (error) {
          console.error("Error updating document:", error);
        }
    }

    async function getdoc(collec, doc_id) {

        const docRef = doc(firestore, collec, doc_id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return {data: docSnap.data()}
        } 
        else {
            // docSnap.data() will be undefined in this case
            return "not found";
        }
    }

    useEffect (() => {
        const unsubscribe = auth.onAuthStateChanged (user => {
        setcurrentusr (user)
        })
        return unsubscribe
    },[])

    const value = {
        currentusr, login, signout, readata, writedata, writedata_id, getdoc, updatedata, writedata_search, uploadstorage, openimg
    }
    return (
        <AuthContext.Provider value = {value}>
        {children}
        </AuthContext.Provider>
        )
}


export const carModelsByManufacturer = {
    acura: ["ILX", "MDX", "RDX", "TLX", "Other"],
    alfa_romeo: ["Giulia", "Stelvio", "Tonale", "Other"],
    aston_martin: ["DB11", "Vantage", "DBX", "Other"],
    audi: ["A3", "A4", "A6", "Q5", "Q7", "Other"],
    bentley: ["Bentayga", "Continental", "Flying Spur", "Other"],
    bmw: ["3 Series", "5 Series", "X3", "X5", "Other"],
    bugatti: ["Chiron", "Veyron", "Divo", "Other"],
    buick: ["Enclave", "Encore", "Envision", "Other"],
    cadillac: ["Escalade", "CT4", "CT5", "Other"],
    chevrolet: ["Silverado", "Malibu", "Tahoe", "Other"],
    chrysler: ["300", "Pacifica", "Other"],
    citroen: ["C3", "C5 Aircross", "Other"],
    dacia: ["Duster", "Sandero", "Other"],
    daewoo: ["Lanos", "Nubira", "Other"],
    daihatsu: ["Terios", "Sirion", "Other"],
    dodge: ["Challenger", "Durango", "Other"],
    ferrari: ["488", "Portofino", "Roma", "Other"],
    fiat: ["500", "Panda", "Tipo", "Other"],
    ford: ["F-150", "Mustang", "Escape", "Other"],
    gmc: ["Sierra", "Yukon", "Other"],
    geely: ["Coolray", "Tugella", "Other"],
    genesis: ["G70", "GV80", "Other"],
    honda: ["Civic", "Accord", "CR-V", "Other"],
    hyundai: ["Elantra", "Santa Fe", "Tucson", "Other"],
    infiniti: ["Q50", "QX60", "Other"],
    isuzu: ["D-Max", "MU-X", "Other"],
    jaguar: ["XF", "F-PACE", "Other"],
    jeep: ["Wrangler", "Grand Cherokee", "Other"],
    kia: ["Seltos", "Sportage", "Sorento", "Other"],
    lamborghini: ["Huracan", "Aventador", "Other"],
    land_rover: ["Defender", "Range Rover", "Other"],
    lexus: ["RX", "NX", "Other"],
    lincoln: ["Navigator", "Aviator", "Other"],
    lotus: ["Evora", "Exige", "Other"],
    maserati: ["Ghibli", "Levante", "Other"],
    mazda: ["CX-5", "Mazda3", "Other"],
    mclaren: ["720S", "Artura", "Other"],
    mercedes: ["C-Class", "E-Class", "Other"],
    mini: ["Cooper", "Countryman", "Other"],
    mitsubishi: ["Outlander", "Pajero", "Other"],
    nissan: ["Altima", "Rogue", "Other"],
    opel: ["Corsa", "Astra", "Other"],
    peugeot: ["208", "3008", "Other"],
    porsche: ["911", "Cayenne", "Other"],
    ram: ["1500", "2500", "Other"],
    renault: ["Clio", "Captur", "Other"],
    rolls_royce: ["Phantom", "Ghost", "Other"],
    saab: ["9-3", "9-5", "Other"],
    seat: ["Ibiza", "Leon", "Other"],
    skoda: ["Octavia", "Kodiaq", "Other"],
    subaru: ["Impreza", "Outback", "Other"],
    suzuki: ["Swift", "Vitara", "Other"],
    tesla: ["Model S", "Model 3", "Model X", "Model Y", "Other"],
    toyota: ["Camry", "Corolla", "RAV4", "Other"],
    volkswagen: ["Golf", "Tiguan", "Other"],
    volvo: ["XC60", "S90", "Other"],
    other: ["Other"]
  };
  
import './App.css';
import React, { useState } from 'react';


import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import { AuthProvider, useAuth } from './context/AuthContextes';
import { Login } from "./pages/login"
import { Usrs } from "./pages/users"
import { Transacs } from './pages/transactions';
import { Addusrs } from './pages/adduser';
import { Accs } from './pages/accidents';
import { Addplan } from './pages/addplan';
import { logEvent } from 'firebase/analytics';


export const AuthContext = React.createContext()

function App() {

  const [currentusr, setcurrentusr] = useState()
  var signout

  function CheckUsr() {

    setcurrentusr(useAuth().currentusr)
    signout = useAuth().signout
  }

  return (

    <Router>
      <AuthProvider>
        <CheckUsr/>
        
        { currentusr ? 

        <div>

          <ul>
            <li><a href="/"> Users </a></li>
            <li><a href="/transactions"> Transaction </a></li>
            <li><a href="/adduser"> Add new user </a></li>
            <li><button style={styles.logoutButton} onClick={ () => { signout() }}> logout </button></li>
          </ul>

          <Routes>
            <Route exact path="/" Component = {Usrs}/>
            <Route path="/accidents" Component = {Accs}/>
            <Route path="/transactions" Component = {Transacs}/>
            <Route path="/adduser" Component = {Addusrs}/>
            <Route path="/addplan/:id" Component = {Addplan}/>
          </Routes> 

        </div>

          :
          <Login/>
        }
        
      
      </AuthProvider>
    </Router>

  );

}

export default App;
const styles= {
  logoutButton:{
  padding: "0.5rem 1rem",
  backgroundColor: "#AA4498",
  color: "white",
  border: "none",
  cursor: "pointer",
  marginTop: "10px",
  borderRadius: "20px",
  },
};
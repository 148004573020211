
export const carManufacturers = [
    { make_id: "acura", make_display: "Acura" },
    { make_id: "alfa_romeo", make_display: "Alfa Romeo" },
    { make_id: "aston_martin", make_display: "Aston Martin" },
    { make_id: "audi", make_display: "Audi" },
    { make_id: "bentley", make_display: "Bentley" },
    { make_id: "bmw", make_display: "BMW" },
    { make_id: "bugatti", make_display: "Bugatti" },
    { make_id: "buick", make_display: "Buick" },
    { make_id: "cadillac", make_display: "Cadillac" },
    { make_id: "chevrolet", make_display: "Chevrolet" },
    { make_id: "chrysler", make_display: "Chrysler" },
    { make_id: "citroen", make_display: "Citroën" },
    { make_id: "dacia", make_display: "Dacia" },
    { make_id: "daewoo", make_display: "Daewoo" },
    { make_id: "daihatsu", make_display: "Daihatsu" },
    { make_id: "dodge", make_display: "Dodge" },
    { make_id: "ferrari", make_display: "Ferrari" },
    { make_id: "fiat", make_display: "Fiat" },
    { make_id: "ford", make_display: "Ford" },
    { make_id: "gmc", make_display: "GMC" },
    { make_id: "geely", make_display: "Geely" },
    { make_id: "genesis", make_display: "Genesis" },
    { make_id: "honda", make_display: "Honda" },
    { make_id: "hyundai", make_display: "Hyundai" },
    { make_id: "infiniti", make_display: "Infiniti" },
    { make_id: "isuzu", make_display: "Isuzu" },
    { make_id: "jaguar", make_display: "Jaguar" },
    { make_id: "jeep", make_display: "Jeep" },
    { make_id: "kia", make_display: "Kia" },
    { make_id: "lamborghini", make_display: "Lamborghini" },
    { make_id: "land_rover", make_display: "Land Rover" },
    { make_id: "lexus", make_display: "Lexus" },
    { make_id: "lincoln", make_display: "Lincoln" },
    { make_id: "lotus", make_display: "Lotus" },
    { make_id: "maserati", make_display: "Maserati" },
    { make_id: "mazda", make_display: "Mazda" },
    { make_id: "mclaren", make_display: "McLaren" },
    { make_id: "mercedes", make_display: "Mercedes-Benz" },
    { make_id: "mini", make_display: "Mini" },
    { make_id: "mitsubishi", make_display: "Mitsubishi" },
    { make_id: "nissan", make_display: "Nissan" },
    { make_id: "opel", make_display: "Opel" },
    { make_id: "peugeot", make_display: "Peugeot" },
    { make_id: "porsche", make_display: "Porsche" },
    { make_id: "ram", make_display: "Ram" },
    { make_id: "range_rover", make_display: "Range Rover" },
    { make_id: "renault", make_display: "Renault" },
    { make_id: "rolls_royce", make_display: "Rolls-Royce" },
    { make_id: "saab", make_display: "Saab" },
    { make_id: "seat", make_display: "SEAT" },
    { make_id: "skoda", make_display: "Škoda" },
    { make_id: "subaru", make_display: "Subaru" },
    { make_id: "suzuki", make_display: "Suzuki" },
    { make_id: "tesla", make_display: "Tesla" },
    { make_id: "toyota", make_display: "Toyota" },
    { make_id: "volkswagen", make_display: "Volkswagen" },
    { make_id: "volvo", make_display: "Volvo" },
    { make_id: "other", make_display: "Other" },
  ];
import React, { useState, useEffect,useRef } from "react";
import { useAuth } from "../context/AuthContextes";

export function Transacs() {
  const { readata, updatedata } = useAuth(); // Add updatedata to useAuth for updating Firebase
  const [transaclst, setransaclst] = useState();
  const [search, setsearch] = useState("")
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [current_transac, setcurrent_transac] = useState(null);

  const searchref = useRef()  
  const notes = useRef();
  const paid = useRef();

  async function getlist(year) {
    try {
      const lst = await readata(`transactions/${year}/transactions`);
      if (lst.length > 0) {
        lst.sort((a, b) => {return a.data.data.date - b.data.data.date})
        setransaclst(lst);
      } else {
        setransaclst(null); 
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setransaclst(null);
    }
  }

  useEffect(() => {
    getlist(selectedYear);
  }, [selectedYear]);

  function updatesrch() {
    setsearch(searchref.current.value)
  }

  function readate(date) {
    return (
      String(date.getDate()) +
      "/" +
      String(date.getMonth() + 1) +
      "/" +
      String(date.getFullYear())
    );
  }



  async function saveTransaction() {
    if (current_transac) {
      const updatedData = {
        notes: notes.current.value,
        status: paid.current.checked, // Boolean value for status
      };

      await updatedata("transactions/" + selectedYear + "/transactions/" + current_transac.idnum, updatedData);
      await getlist(selectedYear);

      setcurrent_transac(undefined); // Clear current transaction
    }
  }

  function Displaylist() {
    if (!transaclst) {
      getlist();
      return <div style={styles.loading}>no transactions...</div>;
    }

    let transaclstfilter = transaclst.filter((value) => {
      if (search == "") {
        return true
      }
      return value.data.data.for_car.includes(search) || value.data.data.person_id.includes(search)
    })

    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>ID Number</th>
            <th style={styles.th}>Car Number</th>
            <th style={styles.th}>Amount</th>
            <th style={styles.th}>From Date</th>
            <th style={styles.th}>Note</th>
            <th style={styles.th}>Status</th>
          </tr>
        </thead>
        <tbody>
          {transaclstfilter.map((val) => (
            <tr
              key={val.idnum}
              id={val.idnum}
              style={{
                ...styles.row,
                backgroundColor:
                  current_transac?.idnum === val.idnum ? "#e3f2fd" : "white",
              }}
              onClick={() => setcurrent_transac(val)}
            >
              <td style={styles.td}>{val.data.data.person_id}</td>
              <td style={styles.td}>{val.data.data.for_car}</td>
              <td style={styles.td}>{val.data.data.amount}</td>
              <td style={styles.td}>
                {readate(new Date(val.data.data.date.seconds * 1000))}
              </td>
              <td style={styles.td}>{val.data.data.notes}</td>

              <td style={styles.td}>
                {val.data.data.status ? (<span style={styles.paidStatus}>paid</span>) : (<span style={styles.unpaidStatus}>not paid</span>)}
              </td>
  
              </tr>
              ))}
        </tbody>
      </table>
    );
  }

  function Update_menu() {
    if (!current_transac) {
      return null; // Hide menu when no transaction is selected
    }

    return (
      <div style={styles.updateMenu}>
        <h3 style={styles.updateHeader}>Update Transaction</h3>
        <div style={styles.formRow}>
          <label htmlFor="paid" style={styles.label}>
            <b>Paid:</b>
          </label>
         <input ref={paid} type="checkbox" defaultChecked={current_transac.data.data.status}/>

        </div>
        <div style={styles.formRow}>
          <label htmlFor="notes" style={styles.label}>
            <b>Notes:</b>
          </label>
          <input
            ref={notes}
            type="text"
            defaultValue={current_transac.data.data.notes}
            placeholder="Write notes"
            style={styles.input}
          />
        </div>
        <div style={styles.buttonContainer}>
          <button
            onClick={() => setcurrent_transac(null)}
            style={styles.closeButton}
          >
            Close
          </button>
          <button onClick={saveTransaction} style={styles.saveButton}>
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.headerTitle}>Transactions</h1>
      </header>

      <input onChange={() => {updatesrch()}} ref = {searchref} type="text" placeholder="Search for Customer/Car number" style={styles.searchInput}>
      </input>

      <div style={styles.filterSection}>
        <label htmlFor="year" style={styles.label}>
          Select Year:
        </label>
        <select
          id="year"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          style={styles.dropdown}
        >
          {Array.from({ length: 10 }, (_, i) => {
            const year = 2023 + i; 
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </div>
      <Displaylist />
      <Update_menu />
    </div>
  );
}

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    backgroundColor: "#f9f9f9",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    color: "black",
    marginBottom: "20px",
  },
  headerTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    backgroundColor: "white",
  },
  th: {
    backgroundColor: "#f4f4f4",
    padding: "10px",
    textAlign: "left",
    borderBottom: "2px solid #ddd",
    fontWeight: "bold",
  },
  td: {
    padding: "10px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  },
  row: {
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  paidStatus: {
    backgroundColor: "rgba(24, 201, 100, 0.2)",
    color: "#155724",
    padding: "5px 10px",
    borderRadius: "15px",
    textAlign: "center",
    fontWeight: "light",
  },
  unpaidStatus: {
    backgroundColor: "#f8d7da",
    color: "#721c24",
    padding: "5px 10px",
    borderRadius: "15px",
    textAlign: "center",
    fontWeight: "light",
  },
  updateMenu: {
    marginTop: "20px",
    padding: "15px",
    backgroundColor: "#f4f4f4",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  },
  updateHeader: {
    marginBottom: "10px",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#333",
  },
  formRow: {
    marginBottom: "10px",
  },
  label: {
    marginRight: "10px",
    fontWeight: "bold",
  },
  input: {
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  closeButton: {
    backgroundColor: "#white",
    color: "#AB439A",
    padding: "5px 10px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  },
  saveButton: {
    backgroundColor: "#AB439A",
    color: "white",
    padding: "5px 10px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  },
  loading: {
    textAlign: "center",
    fontSize: "18px",
    color: "#666",
  },
  filterSection: {
    marginBottom: "20px",
  },
  dropdown: {
    padding: "5px",
    marginRight: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
};

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCm7J_qzjEICPWqh3QJAWWG0Zg509fj3mI",
  authDomain: "crmdatabase-8656d.firebaseapp.com",
  projectId: "crmdatabase-8656d",
  storageBucket: "crmdatabase-8656d.firebasestorage.app",
  messagingSenderId: "1081989003478",
  appId: "1:1081989003478:web:331fdbad02200f24c43177",
  measurementId: "G-Y2XGKT6S8Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const firestorage = getStorage(app)
const analytics = getAnalytics(app);

export {firestorage, firestore, auth};

import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContextes";
import { serverTimestamp } from "firebase/firestore";

export function Addplan() {
  const { readata, writedata, getdoc, updatedata, uploadstorage, openimg } = useAuth();
  const [currentcar, setcurrentcar] = useState();
  const [norm_insur_stat, setnorm_insur_stat] = useState();
  const [vip_insur_stat, setvip_insur_stat] = useState();
  const [req_insur_stat, setreq_insur_stat] = useState();
  const [render_add, setrender_add] = useState(null);
  const [over_res, setover_res] = useState(true);

  const company_name = useRef();
  const start_date = useRef();
  const end_date = useRef();
  const price_amount = useRef();
  const notes = useRef();
  const paid = useRef();

  const mainid = useLocation().pathname.split("/")[2];
  const user_id = mainid.split(":")[0];
  const car_id = mainid.split(":")[1];

  useEffect(() => {
    getcar();
    setinsurances();
  }, []);

  async function getcar() {
    const car = await getdoc(`users/${user_id}/cars`, car_id);
    setcurrentcar(car);
  }

 async function checkinsur(type) {

    var res = true;
    
    const insur_list = await readata(`users/${user_id}/cars/${car_id}/${type}`);

    if (!insur_list || insur_list.length === 0) {
      return { status: "NONE", last_date: null, company: "" };
    }

    const mostRecentPlan = insur_list.reduce((latest, current) => {
      const currentEndDate = new Date(current.data.data.end.seconds * 1000);
      const latestEndDate = new Date(latest?.data?.data?.end.seconds * 1000 || 0);

      return currentEndDate > latestEndDate ? current : latest;
    });

    const endDate = new Date(mostRecentPlan.data.data.end.seconds * 1000);
    const today = new Date();

    let status;
    if (endDate >= today) {
      status = "Active Until :";
    } else {
      status = "Expired :";
      res = false
      setover_res(over_res && res);
    }
    
    await updatedata("users/" + user_id + "/cars/" + car_id, {status: over_res && res});
    await updatedata("users/" + user_id, {status: over_res && res} );
    
    return {
      status,
      last_date: endDate,
      company: mostRecentPlan.data.data.company,
      idnum: mostRecentPlan.idnum,
    };
  }

  async function setinsurances() {
    try {
      const req_status = await checkinsur("required_insurance");
      const vip_status = await checkinsur("vip");
      const norm_status = await checkinsur("normal_insurance");

      setreq_insur_stat({
        company: req_status.company,
        status: req_status.status,
        expiry: req_status.last_date,
        idnum: req_status.idnum,
      });

      setvip_insur_stat({
        company: vip_status.company,
        status: vip_status.status,
        expiry: vip_status.last_date,
        idnum: vip_status.idnum,
      });

      setnorm_insur_stat({
        company: norm_status.company,
        status: norm_status.status,
        expiry: norm_status.last_date,
        idnum: norm_status.idnum,
      });
    } catch (error) {
      console.error("Error setting insurances:", error);
    }
  }

  function openAddMenu(category) {
    setrender_add(category);
  }

  function viewImg(id, category) {
    openimg("insurance/" + user_id + "/" + category + car_id + id + "policy")
  }

  if (!currentcar) {
    return <h1>Loading...</h1>;
  }



  return (
    <div style={styles.container}>
      <h1 style={styles.header}>
        {currentcar.data.data.manufacturer} {currentcar.data.data.model}{" "}
        {currentcar.data.data.year}
      </h1>
      <div style={styles.cardsContainer}>
        <InsuranceCard
          title="Insurance Hova"
          status={req_insur_stat}
          onAdd={() => openAddMenu("required_insurance")}
          viewImg={() => viewImg(req_insur_stat.idnum, "required_insurance")}
        />
        <InsuranceCard
          title="Insurance VIP"
          status={vip_insur_stat}
          onAdd={() => openAddMenu("vip")}
          viewImg={() => viewImg(vip_insur_stat.idnum, "vip")}
        />
        <InsuranceCard
          title="Insurance"
          status={norm_insur_stat}
          onAdd={() => openAddMenu("normal_insurance")}
          viewImg={() => viewImg(norm_insur_stat.idnum, "normal_insurance")}
        />
      </div>
      {render_add && (
        <AddPlanModal
          category={render_add}
          onClose={() => setrender_add(null)}
          onSubmit={(data) => {
            if (data.company == "" || data.start == "" || data.end == "" || data.price == "" || data.img == "") {
              alert("Please fill all missing fields!")
              return
            }
            const categoryData = {
              company: data.company,
              start: new Date(data.start),
              end: new Date(data.end),
              status: 1,
            };

            const transactionData = {
              amount: data.price,
              date: serverTimestamp(),
              for_car: car_id,
              person_id: user_id,
              notes: data.notes,
              status: data.paid,
            };
            writedata(`users/${user_id}/cars/${car_id}/${render_add}`, categoryData)
              .then((transac_id) => {
                  writedata(`transactions/${new Date().getFullYear()}/transactions/`,transactionData)
                  uploadstorage(data.img, user_id, render_add+car_id+transac_id+"policy")
                }
              )
              .then(() => setinsurances())
              .catch((err) => console.error("Error writing data:", err));
            setrender_add(null);
          }}
        />
      )}
    </div>
  );
}



function InsuranceCard({ title, status, onAdd, viewImg }) {
  const readate = (date) => //insurnce card
    `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

  return (
    <div style={styles.card}>
      <h3 style={styles.cardTitle}>{title}</h3>

      {status ? (
        <p style={styles.cardText}>
          {status.company}
          <br />
          {status.status}{" "}
          {status.expiry ? readate(new Date(status.expiry)) : ""}
        </p>
      ) : (
        <p style={styles.cardText}>NONE</p>
      )}

      <button style={styles.button} onClick={onAdd}>
        Add plan
      </button>
      <br/>
      <button style={styles.button} onClick={viewImg}>
        View Policy
      </button>
    </div>
  );
}

function AddPlanModal({ category, onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    company: "",
    start: "",
    end: "",
    price: "",
    notes: "",
    paid: false,
    img: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    }
    else if (type === "file") {
      setFormData((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    }
    else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  return (
    <div style={styles.modal}>
      <div style={styles.modalContent}>
        <span style={styles.closeButton} onClick={onClose}>
          &times;
        </span>

        <h2>Add Plan for {category}</h2>
        <label>Company Name:</label>
        <input
          name="company"
          style={styles.input}
          placeholder="Company"
          value={formData.company}
          onChange={handleInputChange}
        />

        <label>Start Date:</label>
        <input
          name="start"
          style={styles.input}
          type="date"
          value={formData.start}
          onChange={handleInputChange}
        />

        <label>End Date:</label>
        <input
          name="end"
          style={styles.input}
          type="date"
          value={formData.end}
          onChange={handleInputChange}
        />

        <label>Price:</label>
        <input
          name="price"
          style={styles.input}
          type="number"
          value={formData.price}
          onChange={handleInputChange}
        />

        <label>Paid:</label>
        <input
          name="paid"
          style={styles.input}
          type="checkbox"
          checked={formData.paid}
          onChange={handleInputChange}
        />

        <label>Notes:</label>
        <input
          name="notes"
          style={styles.input}
          placeholder="Notes"
          value={formData.notes}
          onChange={handleInputChange}
        />

        <label>Add Policy</label>
        <input
          name="img"
          type="file"
          style={styles.input}
          placeholder="non"
          onChange={handleInputChange}
        />


        <div style={styles.buttonGroup}>
          <button style={styles.button} onClick={onClose}>
            Close
          </button>
          <button style={styles.button} onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
}






const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    padding: "200px",
    // backgroundColor: "#f9f9f9",                                                                               
  },
  header: {
    textAlign: "center",
    fontSize: "24px",
    marginBottom: "20px",
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    
  },
  card: {
    backgroundColor: "#f9f9f9",                                                                               
    borderRadius: "50px",
    padding: "40px",
    width: "200px",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  cardText: {
    fontSize: "20px",
  },
  button: {
    backgroundColor: "#d35aa6",
    color: "#fff",
    border: "none",
    borderRadius: "20px",
    padding: "20px",
    marginBottom: "10px",
    cursor: "pointer",
    marginTop: "40px",
  },
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    width: "400px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  input: {
    display: "block",
    margin: "10px 0",
    padding: "8px",
    width: "100%",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
};
 
export default Addplan;

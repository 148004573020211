import React, { useState, useRef } from 'react';
import { useAuth } from '../context/AuthContextes';
import { carManufacturers } from './carManufacturers';
import { carModelsByManufacturer } from './carModelsByManufacturer';

export function Usrs() {

  const searchref = useRef()  
  const car_num = useRef()
  const car_manu = useRef()
  const car_model = useRef()
  const car_col = useRef()
  const car_year = useRef()


  const {readata} = useAuth()
  const {writedata_id} = useAuth()
  const {writedata_search} = useAuth()
  const [userlst, setuserlst] = useState()
  const [currentusr, setcurrentusr] = useState()
  const [currentcars, setcurrentcars] = useState()
  const [addcar, raiseaddcar] = useState()
  const [search, setsearch] = useState("")

  async function getlist() {
    var lst = await readata("users")
    setuserlst(lst)
  }

  async function getcars(user_id) {
    var lst = await readata("users/" + user_id + "/cars")
    setcurrentcars(lst)
  }

  function updatesrch() {
    setsearch(searchref.current.value)
  }

  function getuser_or_car(val) {
    
    if (search != "") {
      var foundcar = false
      
      for (var i = 0 ; val.data.search != undefined && i < val.data.search.length ; i++) {
        if (val.data.search[i].includes(search)) {
          foundcar = true
        }
      }
      return val.data.data.id_number.includes(search) || foundcar
    }
    else {
      return true
    }
  }

  async function addcardata() {

    let data = {
      manufacturer: car_manu.current.value,
      model: car_model.current.value,
      color: car_col.current.value,
      year: car_year.current.value,
      status: false,
    };
    await writedata_id("users/" + currentusr.idnum + "/cars", car_num.current.value, data)
    
    var search = []
    if (currentusr.data.search != undefined) {
      search = currentusr.data.search
    }
    search.push(car_num.current.value)

    await writedata_search("users", currentusr.idnum, search);

    raiseaddcar(false)
    window.location.reload();
  }

  function Displaylist() {
    if (userlst == undefined) {
      getlist()
    }
    if (userlst !== undefined && addcar != true) {
      
      let userlstfilter = userlst.filter(getuser_or_car)

      return (

        <div>
          <table style={styles.table}>
            <tbody>
            <tr>
              <th> First Name </th>
              <th> Last Name </th>
              <th> Id Number </th>
            </tr>

            
          
            {userlstfilter.map(val => {
              if (val.data.data.status == true) {
                return <tr key = {val.data.data.id_number} style={styles.row} onClick={() => {setcurrentusr(val); getcars(val.data.data.id_number);}}> 
                        <td key={"frnm"+val.data.data.key}>{val.data.data.first_name}</td>
                        <td key={"lstnm"+val.data.data.key}>{val.data.data.last_name}</td>
                        <td key={"id"+val.data.data.key}>{val.data.data.id_number}</td>
                      </tr>
              }
              else {
                return <tr key = {val.data.data.id_number} style={styles.rowissue} onClick={() => {setcurrentusr(val); getcars(val.data.data.id_number);}}> 
                        <td key={"frnm"+val.data.data.key}>{val.data.data.first_name}</td>
                        <td key={"lstnm"+val.data.data.key}>{val.data.data.last_name}</td>
                        <td key={"id"+val.data.data.key}>{val.data.data.id_number}</td>
                      </tr>
              }
              })
            }

          </tbody>
          </table>
        </div>
      )
    }

    if (addcar == true) {
      return (
        <div style={styles.overlay}>
          <div style={styles.modal}>
            <h4 style={styles.header}>Add a New Car</h4>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="carnm">
                <b>Car Registration Number</b>
              </label>
              <input
                ref={car_num}
                type="text"
                placeholder="Car number"
                name="carnm"
                required
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <form>
                <ManufacturerAndModelSelector car_manu={car_manu} car_model={car_model} />
              </form>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="carcol">
                <b>Car Color</b>
              </label>
              <input
                ref={car_col}
                type="text"
                placeholder="Color"
                name="carcol"
                required
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="caryear">
                <b>Manufacturer Year</b>
              </label>
              <input
                ref={car_year}
                type="text"
                placeholder="1999"
                name="caryear"
                required
                style={styles.input}
              />
            </div>
            <div style={styles.buttonGroup}>
              <button
                style={styles.closeButton}
                onClick={() => {
                  raiseaddcar(false);
                }}
              >
                Close
              </button>
              <button style={styles.saveButton} onClick={addcardata}>
                Add
              </button>
            </div>
          </div>
        </div>
      );
    }
    

    else {

      return (
      
        <div> 
        
          <h1>loading</h1>

        </div>

      )

    }
    
  }

  function Displaycarlist() {

    if (currentcars != 0){
      return (
        <div>
          <h2>Cars</h2>
          <table style={styles.table}>
            <tbody>

              <tr>
                <th>  Manufacturer  </th>
                <th>  Model  </th>
                <th>  Color  </th>
                <th>  Number  </th>
                <th>  Year  </th>
                <th>  Status  </th>
              </tr>

              {currentcars.map(val => {
                return <tr key={val.idnum} style={styles.row} onClick={() => {window.location = "/addplan/"+currentusr.idnum+":"+val.idnum}}>
                        <td key={"manu" + val.idnum}>{val.data.data.manufacturer}</td> 
                        <td key={"model" + val.idnum}>{val.data.data.model}</td> 
                        <td key={"col" + val.idnum}>{val.data.data.color}</td> 
                        <td key={"num" + val.idnum}>{val.idnum}</td> 
                        <td key={"year" + val.idnum}>{val.data.data.year}</td> 
                        <td key={"stat" + val.idnum}>{val.data.data.status ? "active" : "issue"}</td> 
                      </tr>
              })}
        
            </tbody>
          </table>
        </div>
      )
    }
    return (
      <h2 style={styles.noCars}> no cars found</h2>
    )
  }

  function Displayuser() {

    if (currentusr && currentcars !== undefined) {
      return (
        <div style={styles.userDetails}>
          <h3>User Details</h3>
          <p>
            <b>Name:</b> {currentusr.data.data.first_name + " " + currentusr.data.data.last_name}
          </p>
          <p>
            <b>Phone Number:</b> {currentusr.data.data.phone_number}
          </p>
          <Displaycarlist />
          <button style={styles.addButton} onClick={() => raiseaddcar(true)}>
            Add Car
          </button>
        </div>
      )
  }

    else {

      return <h2 style={styles.selectUser}>Please Select a User</h2>;

    }

  }

  function ManufacturerAndModelSelector({ car_manu, car_model }) {
    const [selectedManufacturer, setSelectedManufacturer] = useState("");
    const [availableModels, setAvailableModels] = useState([]);
  
    const handleManufacturerChange = (e) => {
      const manufacturer = e.target.value;
      setSelectedManufacturer(manufacturer);
  
      // Dynamically update available models
      const models =
        manufacturer === "Other"
          ? []
          : carModelsByManufacturer[
              carManufacturers.find((make) => make.make_display === manufacturer)
                ?.make_id
            ] || [];
      setAvailableModels(models);
    };
  
    return (
      <div>
        {/* Manufacturer Selection */}
        <label htmlFor="carmanu">
          <b>Manufacturer</b>
        </label>
        {selectedManufacturer === "Other" ? (
          <input
            type="text"
            ref={car_manu}
            name="carmanu"
            placeholder="Enter Manufacturer"
            required
          />
        ) : (
          <select
            ref={car_manu}
            name="carmanu"
            value={selectedManufacturer}
            onChange={handleManufacturerChange}
            required
          >
            <option value="" disabled>
              Select Manufacturer
            </option>
            {carManufacturers.map((make) => (
              <option key={make.make_id} value={make.make_display}>
                {make.make_display}
              </option>
            ))}
          </select>
        )}
  
        {/* Car Model Selection */}
        <label htmlFor="carmodel">
          <b>Car Model</b>
        </label>
        {availableModels !== "Other" ? (
          <select ref={car_model} name="carmodel" required>
            <option value="" disabled>
              Select Model
            </option>
            {availableModels.map((model, index) => (
              <option key={index} value={model}>
                {model}
              </option>
            ))}
          </select>
        ) : (
          <input
            ref={car_model}
            type="text"
            placeholder="Enter Model"
            name="carmodel"
            required
          />
        )}
      </div>
    );
  }

  return (

    <div style={styles.container}>

      <div style={styles.listSection}>
        <input onChange={() => {updatesrch()}} ref = {searchref} type="text" placeholder="Search for Customer ID number" style={styles.searchInput}>
        </input>
        <Displaylist/>
      </div>

      <div style={styles.detailsSection}>
        <Displayuser />
      </div>

    </div>
    
  );
}



const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "2rem",
    padding: "1rem",
    fontFamily: "Arial, sans-serif",
  },
  listSection: {
    flex: 2,
    maxWidth: "500px",
    height: "400px", 
    overflowY: "auto",
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  },
  detailsSection: {
    flex: 1,
    padding: "1rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    textAlign: "left",
  },
  row: {
    cursor: "pointer",
    transition: "background-color 0.3s",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  },
  rowissue: {
    cursor: "pointer",
    backgroundColor: "#ffd6d6",
    transition: "background-color 0.3s",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  },
  searchInput: {
    width: "50%",
    padding: "0.5rem",
    marginBottom: "1rem",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  addButton: {
    padding: "0.5rem 1rem",
    backgroundColor: "#AA4498",
    color: "white",
    border: "none",
    cursor: "pointer",
    marginTop: "1rem",
    borderRadius: "20px",
  },
  buttonFind: {
    padding: "0.5rem 1rem",
    backgroundColor: "#AA4498",
    color: "white",
    border: "none",
    cursor: "pointer",
    marginTop: "1rem",
    borderRadius: "20px",
  },
  saveButton: {
    backgroundColor: "#AB439A",
    color: "white",
    padding: "10px 15px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    fontSize: "16px",
  },
  closeButton: {
    backgroundColor: "#white",
    color: "#AB439A",
    padding: "10px 15px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    fontSize: "16px",

  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "90%",
    maxWidth: "400px",
    padding: "20px",
    textAlign: "center",
  },
  header: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
  },
  formGroup: {
    marginBottom: "15px",
    textAlign: "left",
  },
  label: {
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: "5px",
    display: "block",
    color: "#555",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "16px",
    boxSizing: "border-box",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
};
